import { Skeleton, styled } from '@mui/material'

export const StyledPlpHeaderSkeletonWrapper = styled('div', {
  name: 'PlpHeaderSkeleton',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),

  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(8),
  },
}))

export const StyledPlpHeaderSkeletonTitle = styled(Skeleton, {
  name: 'PlpHeaderSkeleton',
  slot: 'Title',
})(({ theme }) => ({
  height: '36px',
  width: '70%',
  borderRadius: 0,

  [theme.breakpoints.up('md')]: {
    height: '42px',
    width: '40%',
  },
}))

export const StyledPlpHeaderSkeletonFilters = styled('div', {
  name: 'PlpHeaderSkeleton',
  slot: 'Filters',
})(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(8),
  justifyContent: 'flex-end',

  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(12),
  },
}))

export const StyledPlpHeaderSkeletonFilterButton = styled(Skeleton, {
  name: 'PlpHeaderSkeleton',
  slot: 'FilterButton',
})(({ theme }) => ({
  display: 'block',
  height: '32px',
  width: '80px',

  [theme.breakpoints.up('md')]: {
    width: '100px',
  },
}))

export const StyledPlpHeaderSkeletonResults = styled(Skeleton, {
  name: 'PlpHeaderSkeleton',
  slot: 'Title',
})(({ theme }) => ({
  width: '20%',
  borderRadius: 0,

  [theme.breakpoints.up('md')]: {
    width: '10%',
  },
}))
