import { StyledTypography } from '@components/UI/Typography'
import config from '@configs/config.base'
import { useSite } from '@foundation/hooks/useSite'
import { useFrameGenius } from '@hooks/useFrameGenius'
import useTheme from '@mui/material/styles/useTheme'
import { IProduct } from '@typesApp/product'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { CtaContainer, ImageContainer, SizeTable, SizeTableTd, SizeTableTr, StyledButton } from './styles'

const sizeGude1 = config.publicUrl + 'images/common/sizeGuide-1.png'
const sizeGude2 = config.publicUrl + 'images/common/sizeGuide-2.png'
const sizeGude3 = config.publicUrl + 'images/common/sizeGuide-3.png'
const sizeGude4 = config.publicUrl + 'images/common/sizeGuide-4.png'

type Props = {
  currentProduct: IProduct | undefined
}

const Table: React.FC = () => {
  const { t } = useTranslation()
  const tableData = [
    [t('SizeFitGuide.category.size'), 'xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl'],
    [t('SizeGuide.Labels.millimeters'), '<121', '121-124', '125-128', '129-132', '133-136', '137-140', '>140'],
    [t('SizeGuide.Labels.inches'), '<4.8', '4.8-4.9', '4.9-5', '5.1-5.2', '5.3-5.4', '5.4-5.5', '>5.5'],
  ]

  return (
    <SizeTable>
      {tableData.map((col, ind) => {
        return (
          <SizeTableTd key={ind} highLighted={ind === 0}>
            {col.map(row => (
              <SizeTableTr key={row}>{row}</SizeTableTr>
            ))}
          </SizeTableTd>
        )
      })}
    </SizeTable>
  )
}

export const SizeGuide = React.forwardRef<HTMLDivElement, Props>(function SizeGuide(_, ref) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { openSizeAdvisor } = useFrameGenius()
  const { mySite } = useSite()
  const frameGeniusStoreconfs = mySite.xStoreCfg?.frameGenius
  const isSizeAdvisorEnabled = frameGeniusStoreconfs.isSizeAdvisorEnabled

  return (
    <div ref={ref as unknown as React.LegacyRef<HTMLDivElement>}>
      <StyledTypography
        isUppercased
        marginBottom={4}
        fontSize={20}
        fontWeight={'600'}
        color={theme.palette.primary.dark}
      >
        {t('SizeFitGuide.size.title1')}
      </StyledTypography>
      <StyledTypography
        marginBottom={4}
        color={theme.palette.primary.dark}
        dangerouslySetInnerHTML={{
          __html: t('SizeFitGuide.size.description1') || '',
        }}
      />
      <Table />
      <ImageContainer>
        <img src={sizeGude1} title="." />
      </ImageContainer>
      <StyledTypography
        isUppercased
        marginBottom={4}
        fontSize={20}
        fontWeight={'600'}
        color={theme.palette.primary.dark}
      >
        {t('SizeFitGuide.size.title2')}
      </StyledTypography>
      <StyledTypography
        marginBottom={4}
        color={theme.palette.primary.dark}
        dangerouslySetInnerHTML={{
          __html: t('SizeFitGuide.size.description2') || '',
        }}
      />
      {isSizeAdvisorEnabled && (
        <CtaContainer>
          <StyledTypography marginBottom={4} color={theme.palette.primary.dark} textAlign={'center'}>
            {t('SizeFitGuide.size.saDescription')}
          </StyledTypography>
          <StyledButton fullWidth onClick={openSizeAdvisor}>
            {t('SizeFitGuide.size.saCta')}
          </StyledButton>
        </CtaContainer>
      )}

      <StyledTypography
        isUppercased
        marginBottom={4}
        fontSize={20}
        fontWeight={'600'}
        color={theme.palette.primary.dark}
      >
        {t('SizeFitGuide.size.title3')}
      </StyledTypography>

      <StyledTypography
        color={theme.palette.primary.dark}
        dangerouslySetInnerHTML={{
          __html: t('SizeFitGuide.size.description3') || '',
        }}
      />
      <ImageContainer>
        <img src={sizeGude2} title="." />
      </ImageContainer>

      <StyledTypography
        color={theme.palette.primary.dark}
        dangerouslySetInnerHTML={{
          __html: t('SizeFitGuide.size.description4') || '',
        }}
      />
      <ImageContainer>
        <img src={sizeGude3} title="." />
      </ImageContainer>
      <ImageContainer>
        <img src={sizeGude4} title="." />
      </ImageContainer>
    </div>
  )
})
