import { IProduct } from '@typesApp/product'
import { useTranslation } from 'next-i18next'
import { Skeleton, Typography } from '@mui/material'
import { StyledBrand } from './ProductNameCLSection.style'
import Link from '@components/UI/Link'
import { ICMExternalChannel } from '@typesApp/cmsPlacement/CMExternalChannel'

interface IProductDetailsList {
  currentProduct: IProduct
  brandList: ICMExternalChannel[] | undefined
  brandPrefix?: string
  loading?: boolean
}

const getBrandLink = (
  currentProduct: IProduct,
  brandList: ICMExternalChannel[] | undefined,
  brandPlaceholder: string
) => {
  const brandName = currentProduct?.productAttributes['CL_BRAND_FAMILY'] || brandPlaceholder
  const url = brandList?.find(list => list?.title?.toLowerCase() === brandName?.toLowerCase())?.formattedUrl
  return url ? (
    <Link href={url} data-name="brand">
      {brandName}
    </Link>
  ) : (
    <span>{brandName}</span>
  )
}

export const ProductNameCLSection = ({ currentProduct, brandList, brandPrefix, loading }: IProductDetailsList) => {
  const { t } = useTranslation()
  const brandLink = getBrandLink(currentProduct, brandList, t('ProductDetails.Labels.BrandName'))

  return (
    <>
      <Typography variant="h4" component="h1">
        {loading ? <Skeleton width="50%" /> : currentProduct?.productAttributes['MODEL_NAME']}
      </Typography>
      <StyledBrand variant="body1">
        {loading ? (
          <Skeleton width="40%" />
        ) : (
          <>
            {brandPrefix} {brandLink}
          </>
        )}
      </StyledBrand>
    </>
  )
}
