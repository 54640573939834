import { Divider } from '@mui/material'
import {
  StyledPlpHeaderSkeletonFilterButton,
  StyledPlpHeaderSkeletonFilters,
  StyledPlpHeaderSkeletonResults,
  StyledPlpHeaderSkeletonTitle,
  StyledPlpHeaderSkeletonWrapper,
} from './PlpHeaderSkeleton.style'

function PlpHeaderSkeleton() {
  return (
    <StyledPlpHeaderSkeletonWrapper>
      <StyledPlpHeaderSkeletonTitle />
      <StyledPlpHeaderSkeletonFilters>
        <StyledPlpHeaderSkeletonFilterButton />
        <StyledPlpHeaderSkeletonFilterButton />
      </StyledPlpHeaderSkeletonFilters>
      <Divider />
      <StyledPlpHeaderSkeletonResults />
    </StyledPlpHeaderSkeletonWrapper>
  )
}

export default PlpHeaderSkeleton
