import { FC } from 'react'
// TYPES
import { Attachment, CrossOriginAnonymous, ProductType } from '../../../types/product'
// SWIPER CSS
import BrandIcon from '@components/BrandIcon'
import { ProductImageProps } from '@components/ProductImage/ProductImage'
import { ArrowDownIcon, ArrowUpIcon } from '@components/UI/Icons/arrows'
import { ProductMedia } from '@components/common/Media/ProductMedia'
import { VerticalCarousel } from '@components/common/components/VerticalCarousel'
import { useTheme } from '@mui/material/styles'
import {
  SliderNavigationButton,
  SliderThumbNailContainer,
  SliderThumbNailsSekeleton,
  SliderThumbNailsWrapper,
  ThumbNailBrandContainer,
  ThumbnailButtonContainer,
  WrapperThumbnails,
} from './ProductImagesSlider/ProductImagesCarousel.style'

/**
 * @param { Attachment[] } images thumbnails images
 * @param { number } currentIndex thumbnails selected index
 * @param { ProductImageProps } thumbnailImageProps thumbnails images props
 */

export interface ThumbnailsProps {
  productType?: ProductType | null
  thumbnailImageProps: ProductImageProps
  images?: Attachment[]
  currentIndex: number
  brandName?: string
  onThumbnailClick?: (imageIndex: number) => void
  isGalleryMode?: 1 | 0
  loading?: boolean
}

const wrapperHeight = 345

const ProductImagesThumbnails: FC<ThumbnailsProps> = ({
  images,
  currentIndex,
  onThumbnailClick,
  thumbnailImageProps,
  brandName,
  isGalleryMode,
  loading,
}: ThumbnailsProps) => {
  const isPositionStart = currentIndex === 0
  const isPositionEnd = images?.length === currentIndex + 1
  const theme = useTheme()

  return (
    <WrapperThumbnails isGalleryMode={isGalleryMode}>
      {brandName && (
        <ThumbNailBrandContainer>
          <BrandIcon name={brandName} width={80} />
        </ThumbNailBrandContainer>
      )}
      <SliderThumbNailsWrapper height={wrapperHeight}>
        {images && images?.length > 0 && (
          <VerticalCarousel slideIndex={currentIndex} setSlideIndex={onThumbnailClick} slidesPerView={5}>
            {images.map((t, i) => (
              <SliderThumbNailContainer
                key={i}
                selected={currentIndex === i}
                isProductImage={true}
                onClick={() => onThumbnailClick && onThumbnailClick(i)}
              >
                {loading ? (
                  <SliderThumbNailsSekeleton />
                ) : (
                  <ProductMedia
                    attachments={[t]}
                    backgroundColor={theme.palette.background.light.primary}
                    {...thumbnailImageProps}
                    crossOrigin={CrossOriginAnonymous}
                  />
                )}
              </SliderThumbNailContainer>
            ))}
          </VerticalCarousel>
        )}
      </SliderThumbNailsWrapper>
      {images && images?.length > 5 && (
        <ThumbnailButtonContainer>
          <SliderNavigationButton
            aria-label="SliderNavigationButtonUp"
            positionStart={isPositionStart}
            onClick={() => currentIndex && currentIndex !== 0 && onThumbnailClick && onThumbnailClick(currentIndex - 1)}
          >
            <ArrowUpIcon htmlColor={theme.palette.custom.boulder} />
          </SliderNavigationButton>
          <SliderNavigationButton
            aria-label="SliderNavigationButtonDown"
            positionEnd={isPositionEnd}
            onClick={() =>
              images?.length !== currentIndex + 1 && onThumbnailClick && onThumbnailClick(currentIndex + 1)
            }
          >
            <ArrowDownIcon htmlColor={theme.palette.custom.boulder} />
          </SliderNavigationButton>
        </ThumbnailButtonContainer>
      )}
    </WrapperThumbnails>
  )
}

export default ProductImagesThumbnails
