import { Typography } from '@mui/material'
import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

const PlpHeaderTitle: FC<{
  text?: string
  isSticky?: boolean
  catalogLoading?: boolean
}> = ({ text, catalogLoading }) => (
  <Typography variant="h5" component="h1">
    {catalogLoading || !text ? <Skeleton /> : text}
  </Typography>
)
export default PlpHeaderTitle
