import { StyledGridProductLayout, StyledProductGridContainer, StyledProductWrap } from './ProductGridView.style'
import { ProductTileLoading } from '@components/ProductTile'

const ProductsSkeleton = () => {
  return (
    <StyledProductGridContainer>
      {[...Array(6).keys()].map(index => (
        <StyledGridProductLayout key={index}>
          <StyledProductWrap>
            <ProductTileLoading />
          </StyledProductWrap>
        </StyledGridProductLayout>
      ))}
    </StyledProductGridContainer>
  )
}

export default ProductsSkeleton
