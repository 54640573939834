import { styled, Typography } from '@mui/material'
import { useTranslation } from 'next-i18next'

export const StyledImageNotAvailablePlaceholderContainer = styled('div', {
  name: 'ProductImageNotAvailable',
  slot: 'Container',
})(({ theme }) => ({
  background: theme.palette.background.light.secondary,
  aspectRatio: '1/1',
  padding: theme.spacing(4),
  width: '100%',
  height: '100%',

  display: 'flex',
  flexDirection: 'column',

  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(8),

  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(21),
    marginRight: theme.spacing(19.5),
    height: 336,
  },
}))

const ProductImageNotAvailable = () => {
  const { t } = useTranslation()

  return (
    <StyledImageNotAvailablePlaceholderContainer>
      <Typography variant="subtitle1">{t('ImageNotAvailable.title')}</Typography>
    </StyledImageNotAvailablePlaceholderContainer>
  )
}

export default ProductImageNotAvailable
