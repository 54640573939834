import { Button } from '@components/UI/Button'
import { GridContainer, GridItem } from '@components/UI/Grid'
import { Pill } from '@components/UI/Pill'
import { Link } from '@components/common/Link/Link'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles/createTheme'
import styled from '@mui/material/styles/styled'
import { gridColumns } from '../../constants/ui'
import { ProductColorSelectorContainer } from './components/ProductColorSelector/ProductColorSelector.style'
import ProductImagesCarousel from './components/ProductImagesSlider/ProductImagesCarousel'
import { ProductNameWrapper as CLProductNameWrapper } from './components/ProductNameCLSection.style'
import { ProductNameWrapper } from './components/ProductNameSection.style'
import { ProductSizeWrapper } from './components/ProductSize.style'
import { StyledSpecialFrameInfoWrapper } from './components/StyledSpecialFrameInfo'
import {
  FormControl,
  FormLabel as MuiFormConfigLabel,
  formLabelClasses as MuiFormConfigLabelClasses,
  Select,
  Skeleton,
} from '@mui/material'
import MenuItem from '@mui/material/MenuItem'

const PdpContainer = styled(GridContainer, {
  name: 'PdpContainer',
  slot: 'MainContainer',
})(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(0),
  marginTop: theme.spacing(12),
  gridTemplateColumns: `repeat(${gridColumns['xs']}, 1fr)`,
  gap: theme.spacing(16),
}))

const PdpLeftColumn = styled(GridItem, {
  name: 'PdpContainer',
  slot: 'LeftColumn',
})(() => ({
  width: '100%',
}))

const StyledTitleContent = styled('div', {
  name: 'PdpContainer',
  slot: 'Title',
})(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}))

const StyledProductName = styled(Typography, {
  name: 'PdpContainer',
  slot: 'ProductName',
})(() => ({}))

const StyledSeparator = styled('hr', {
  name: 'ProductDetails',
  slot: 'Separator',
})(({ theme }) => ({
  height: 1,
  margin: 0,
  border: 'none',
  alignSelf: 'stretch',
  flexGrow: 0,
  backgroundColor: theme.palette.background.light.tertiary,
}))

export const StyledFormControl = styled(FormControl, {
  name: 'ProductDetails',
  slot: 'FormControl',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))
export const StyledFormConfigLabel = styled(MuiFormConfigLabel, {
  name: 'ProductDetails',
  slot: 'FormConfigLabel',
})(({ theme }) => ({
  [`&.${MuiFormConfigLabelClasses.root}`]: {
    ...theme.typography.body1,
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(5),
    color: theme.palette.neutral.main,
  },
}))
export const StyledSelect = styled(Select, {
  name: 'ProductDetails',
  slot: 'ProductDetailsCLASelect',
})(({ theme }) => ({
  minWidth: theme.spacing(72.5),
  backgroundColor: theme.palette.common.white,
  maxHeight: theme.spacing(20),
}))

export const StyledMenuItem = styled(MenuItem, {
  name: 'ProductDetails',
  slot: 'MenuItem',
})(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.neutral.tones[90],
  },
}))

export const StyledContainer = styled('div', {
  name: 'PdpContainer',
  slot: 'Enabler',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: theme.spacing(4),
  margin: 'auto',
}))

export const StyledSectionTitle = styled('div', {
  name: 'PdpContainer',
  slot: 'SectionTitle',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: 14,
  lineHeight: 1.43,
}))

export const StyledSwitcherContainer = styled('div', {
  name: 'PdpContainer',
  slot: 'SwitcherContainer',
})(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
}))

export const Background = styled('div', {
  name: 'VirtualMirror',
  slot: 'Container',
})(({ theme }) => ({
  content: '""',
  height: '100vh',
  width: '100%',
  overflow: 'hidden',
  position: 'fixed',
  top: '0',
  left: '0',
  backgroundColor: theme.palette.background.dark.primary,
  zIndex: 9900,
}))

export const VMContainer = styled('div', {
  name: 'VirtualMirror',
  slot: 'Container',
  shouldForwardProp: prop => prop === 'isOpen',
})<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  backgroundColor: theme.palette.background.dark.primary,
  fontFamily: `${theme.typography.fontFamily} !important`,
  maxWidth: '768px',
  height: '100%',
  width: '100%',
  zIndex: 9999,
  position: 'fixed',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  margin: 'auto',
  '.is-vm-open': {
    body: {
      overflowY: isOpen ? 'hidden' : 'auto',
    },
  },
  '.vmmv-drawer-screenshot': {
    maxWidth: '48em',
    width: '100%',
    left: 0,
  },
  '.vmmv-screenshot-wrapper': {
    padding: '3em 10%',
    overflowY: 'auto',
    'img:nth-of-type(3)': {
      width: 'auto',
    },
  },
  '.swiper-slide': {
    width: '80px',
  },
  '[class^="styles__ActionBox-sc-"]': {
    maxWidth: '20%',
    fontWeight: '600 !important',
  },
  '[class^="styles__ActionBox-sc-"],[class^="styles__ProductInfo-sc-"],[class^="styles__TipDescription-sc-"], [class^="styles__ParagraphDescription-sc-"], [class^="styles__Title-sc-"], #bipa-component-container, #PRIVACY_IMPLICIT_TERMS_AND_CONDITIONS, #PRIVACY_IMPLICIT_AGREEMENT, #PRIVACY_IMPLICIT_AGREEMENT b, #PRIVACY_AGREEMENT_TITLE, #PRIVACY_FIRST_AGREEMENT, #privacy-agreement p span, #privacy-agreement p':
    {
      fontFamily: `${theme.typography.fontFamily} !important`,
      color: `${theme.palette.background.dark.primary} !important`,
    },
  "[class^='styles__ListText-sc-']": {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  "[class^='styles__Title-sc-']": {
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'nowrap !important',
    },
  },
  "[class^='styles__SeeInstructions']": {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  "[class^='styles__StyledLabel']": {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  "[class^='loading-text']": {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  "[class^='styles__PrivacyNotice']": {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  "[class^='styles__ErrorLabel']": {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  "[class^='styles__ErrorMessage']": {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  '.upload-image-display-controls > .upload-image-display-accept': {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  '.upload-image-display-controls > .upload-image-display-accept::after': {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  '.upload-image-display-controls > .upload-image-display-decline': {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  '.upload-image-display-controls > .upload-image-display-decline::after': {
    fontFamily: `${theme.typography.fontFamily} !important`,
    position: 'static !important',
  },
  "[class^='fa-nudging-guide']": {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  "[class^='styles__ButtonsContainer']": {
    position: 'static !important',
    marginTop: 'auto',
  },
  "[class^='styles__Tip-sc']": {
    '@media (min-width: 768px)': {
      marginTop: '1em',
    },
  },
  "[class^='styles__PictureModeEntryPageWraper-sc']": {
    "[class^='Button__StyledButton-sc']": {
      fontFamily: `${theme.typography.fontFamily} !important`,
      '@media only screen and (min-width: 768px)': {
        marginTop: '5em',
      },
    },
  },
  '#PRIVACY_AGREEMENT_TITLE': {
    margin: '0 !important',
  },
  '#bipa-submit-button span': {
    fontWeight: '600 !important',
  },
  '#bipa-cancel-button': {
    fontFamily: `${theme.typography.fontFamily} !important`,
    borderRadius: '0 !important',
    border: `1px solid ${theme.palette.background.dark.primary} !important`,
    color: `${theme.palette.background.dark.primary} !important`,
    textTransform: 'uppercase',
    '#PRIVACY_BUTTON_CANCEL': {
      fontWeight: '600 !important',
    },
  },
  '#bipa-submit-button, [class^="Button__StyledButton-sc-"], #upload-image-drop-zone, .upload-image-display-accept, [class^="styles__TryAgainButton-sc-"]':
    {
      fontFamily: `${theme.typography.fontFamily} !important`,
      backgroundColor: `${theme.palette.background.dark.primary} !important`,
      color: `${theme.palette.background.dark.secondary} !important`,
      borderRadius: '0 !important',
      textTransform: 'uppercase',
      fontWeight: '600 !important',
      '&:hover': {
        backgroundColor: `${theme.palette.background.dark.tertiary} !important`,
        borderColor: `${theme.palette.background.dark.tertiary} !important`,
      },
    },
  '.yes-no-checkbox .switch .slider': {
    backgroundColor: '#99ACB1 !important',
  },
  '.yes-no-checkbox .switch .slider.round::before': {
    backgroundColor: '#CDE9E4 !important',
  },
  '.yes-no-checkbox .switch input:checked + .slider': {
    backgroundColor: `${theme.palette.background.dark.secondary} !important`,
  },
  '.yes-no-checkbox .switch input:checked + .slider::before': {
    backgroundColor: `${theme.palette.background.dark.primary} !important`,
  },
  "[class^='styles__FooterInner']": {
    "[class^='styles__IconWrapper']": {
      background: theme.palette.color.grey,
      '&:hover': {
        backgroundColor: `${theme.palette.background.dark.tertiary} !important`,
        borderColor: `${theme.palette.background.dark.tertiary} !important`,
      },
    },
    "[class^='styles__FooterLabelWrapper'] span": {
      fontFamily: `${theme.typography.fontFamily} !important`,
      "[class^='styles__PriceStyled']:nth-of-type(2)": {
        fontSize: '1em',
      },
    },
    "[class^='Button__StyledButton'] span": {
      fontFamily: `${theme.typography.fontFamily} !important`,
      background: theme.palette.background.dark.primary,
      border: `1px solid ${theme.palette.background.dark.primary}`,
      color: theme.palette.background.dark.secondary,
      borderRadius: 0,
      '&:hover': {
        backgroundColor: `${theme.palette.background.dark.tertiary} !important`,
        borderColor: `${theme.palette.background.dark.tertiary} !important`,
      },
    },
  },
  '[class^="styles__PictureModeEntryPageWraper-sc-"]': {
    '@media only screen and (min-width: 1224px)': {
      padding: '5em 7em 2em',
    },
  },
  '#upload-image-drop-zone::after, .upload-image-display-accept::after': {
    fontFamily: `${theme.typography.fontFamily} !important`,
    color: `${theme.palette.background.dark.secondary} !important`,
    fontWeight: '600 !important',
  },
  'button, [class^="styles__MenuLogo-sc-"], [class^="styles__CatalogItem-sc-"], [class^="styles__IconWrapper-sc-"], [class^="styles__ActionBtn-sc-"], [class^="styles__ActionBox-sc-"]>svg':
    {
      cursor: 'pointer',
      fill: theme.palette.background.dark.primary,
      stroke: `${theme.palette.background.dark.primary} !important`,
      strokeWidth: '0.5',
    },
  '#bipa-overlay-root-style.bipa-overlay #bipa-component-container button span': {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  '#bipa-overlay-root-style.bipa-overlay b': {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
}))

export const VMWrapper = styled('div', {
  name: 'VirtualMirror',
  slot: 'Wrapper',
})(() => ({
  backgroundColor: '#000',
  inset: 0,
  opacity: 0.3,
  position: 'fixed',
  zIndex: 9998,
}))

const productNameComponentClassNames = [ProductNameWrapper, CLProductNameWrapper].filter(c => c).join(', ')

const pdpRightColumnFramesContentSorting = (theme: Theme) => ({
  // divider line #1
  [`& > ${StyledSeparator}:nth-of-type(1)`]: {
    display: 'none',
  },

  [theme.breakpoints.down('md')]: {
    '& > *': { order: 6 },
    // product name
    [productNameComponentClassNames]: { order: 1 },
    // divider line #1
    [`${StyledSeparator}:nth-of-type(1)`]: { display: 'initial', order: 2 },
    // product size
    [`& > ${ProductSizeWrapper}`]: { order: 5 },
    // special frame info
    [`& > ${StyledSpecialFrameInfoWrapper}`]: { order: 6 },
    // divider line #2
    [`${StyledSeparator}:nth-of-type(2)`]: { order: 4 },
    // colors (MoCos) selector
    [`& > ${ProductColorSelectorContainer}`]: { order: 3 },
  },
})

const PdpRightColumn = styled(GridItem, {
  name: 'PdpContainer',
  slot: 'RightColumn',
  shouldForwardProp: prop =>
    prop !== 'enableFramesContentSorting' && prop !== 'visible' && prop !== 'isTabletLandscape',
})<{ enableFramesContentSorting?: boolean; visible?: boolean; isTabletLandscape: boolean }>(
  ({ enableFramesContentSorting = false, theme, visible }) => ({
    display: visible ? 'flex' : 'none',
    flexDirection: 'column',
    gap: theme.spacing(12),
    borderRadius: theme.spacing(4),
    height: 'fit-content',

    [theme.breakpoints.up('md')]: {
      width: '100%',
    },

    ...(enableFramesContentSorting ? pdpRightColumnFramesContentSorting(theme) : {}),
  })
)

const StyledPdpSummary = styled(GridItem, {
  name: 'PdpContainer',
  slot: 'PdpSummary',
  shouldForwardProp: prop =>
    prop !== 'enableFramesContentSorting' && prop !== 'visible' && prop !== 'isTabletLandscape',
})<{ enableFramesContentSorting?: boolean; visible?: boolean; isTabletLandscape: boolean }>(
  ({ enableFramesContentSorting = false, theme, visible }) => ({
    display: visible ? 'flex' : 'none',
    flexDirection: 'column',
    backgroundColor: '#FAFAFB',
    gap: theme.spacing(8),
    padding: theme.spacing(8),
    borderRadius: theme.spacing(4),
    height: 'fit-content',

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(12),
      width: '100%',
    },

    ...(enableFramesContentSorting ? pdpRightColumnFramesContentSorting(theme) : {}),
  })
)

const StyledLeftColumnContainer = styled('div', {
  name: 'PdpContainer',
  slot: 'LeftColumContainer',
  shouldForwardProp: prop => prop === 'visible' || prop === 'children',
})<{
  visible?: 1 | 0
}>(({ visible, theme }) => ({
  display: visible ? 'flex' : 'none',
  flexDirection: 'column',
  width: '100%',

  [theme.breakpoints.up('md')]: {
    top: theme.spacing(3),
  },
}))

const VirtualMirrorWrapper = styled('div', {
  name: 'PdpContainer',
  slot: 'VirtualMirrorWrapper',
})(() => ({
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  position: 'sticky',
}))

const StyledButton = styled('div', {
  name: 'PdpContainer',
  slot: 'StyledButton',
})(({ theme }) => ({
  fontSize: theme.spacing(2.5),
  fontWeight: '600',
  textTransform: 'uppercase',
  color: theme.palette.text.dark.primary,
  backgroundColor: theme.palette.background.light.secondary,
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  border: `1px solid ${theme.palette.background.light.tertiary}`,
  borderRadius: theme.spacing(10),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
  cursor: 'pointer',
  margin: `${theme.spacing(4)} 0`,

  [theme.breakpoints.up('md')]: {
    margin: '0',
  },
}))

const SoldOutImagePlaceholderContainer = styled('div', {
  name: 'PdpContainer',
  slot: 'SoldOutImagePlaceholder',
})(({ theme }) => ({
  background: theme.palette.background.light.tertiary,
  width: 672,
  height: 336,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(8),
  marginRight: theme.spacing(19.5),
  marginTop: theme.spacing(21),
  padding: `${theme.spacing(18)} 0`,
}))

const SoldOutTitle = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(10),
  color: theme.palette.text.light.secondary,
}))

const SoldOutDescription = styled('div')(({ theme }) => ({
  width: 304,
  fontSize: theme.spacing(5),
  color: theme.palette.text.light.secondary,
}))

const SliderWrapper = styled('div', {
  name: 'PdpDetails',
  slot: 'SliderWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  justifyContent: 'flex-end',
  alignSelf: 'center',
  width: '100%',
  gap: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}))

const ProductFullPageGalleryThumbsSlider = styled(ProductImagesCarousel, {
  name: 'ProductFullPageGallery',
  slot: 'ThumbsSlider',
})(({ theme }) => ({
  width: '100%',
  '.slider-list': {
    padding: `0 ${theme.spacing(4)} ${theme.spacing(6)} ${theme.spacing(4)}`,
    justifyContent: 'space-evenly',
  },
  '.slide': {
    cursor: 'pointer',
    position: 'relative',
    height: `calc(${theme.spacing(14)} - 2px )`,
    borderRadius: theme.spacing(2),
    paddingBottom: '2px',
    transitionDuration: '0ms',
    margin: `${theme.spacing(0, 8)}`,

    [theme.breakpoints.up('sm')]: {
      margin: `${theme.spacing(0, 20)}`,
    },
    '[class*="-closeIcon"]': {
      border: `1px solid  ${theme.palette.text.light.primary}`,
      borderRadius: theme.spacing(2),
      '&:hover': {
        border: `1px solid  ${theme.palette.text.dark.tertiary}`,
      },
    },
    '.selected': {
      border: `1px solid  ${theme.palette.neutral.tones[60]}`,
    },
    img: {
      objectFit: 'contain',
      height: '100%',
      borderRadius: '4px',
    },
    'span.lazy-load-image-background': {
      width: '100%',
      height: 56,
      lineHeight: '0',
    },
  },
  '.swiper-horizontal .swiper-pagination-progressbar': {
    width: '50vw',
    left: '25vw',
    height: '2px',
    top: '60%',
    bottom: '0',
    backgroundColor: theme.palette.background.light.tertiary,
    '&-fill': {
      backgroundColor: theme.palette.color.black,
    },
  },
}))

const ProductFullPageGalleryImagesSlider = styled(ProductImagesCarousel, {
  name: 'ProductFullPageGallery',
  slot: 'ImagesSlider',
})(({ theme }) => ({
  marginBottom: theme.spacing(15),
}))

const ProductImagesMainSlider = styled(ProductImagesCarousel, {
  name: 'ProductImages',
  slot: 'MainSlider',
})(({ theme }) => ({
  aspectRatio: '1 / 1',
  position: 'relative',

  [theme.breakpoints.up('md')]: {
    width: `calc(100% - ${theme.spacing(32)})`,
    paddingRight: theme.spacing(16),
    paddingLeft: theme.spacing(16),
    flex: '1 1 auto',

    '.slider-list': {
      cursor:
        "url(\"data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%238afjeh34ua)'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M20.436 19.73a6.5 6.5 0 1 0-.707.707l4.418 4.417.707-.707-4.418-4.418z' fill='%23fff'/%3E%3C/g%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M20.436 19.73a6.5 6.5 0 1 0-.707.707l4.418 4.417.707-.707-4.418-4.418zM15.5 21a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zM12 15.002v1h3V19h1v-2.998h3v-1h-3V12h-1v3.002h-3z' fill='%2300303C'/%3E%3Cdefs%3E%3Cfilter id='8afjeh34ua' x='6' y='7' width='21.855' height='21.854' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='1'/%3E%3CfeGaussianBlur stdDeviation='1.5'/%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0'/%3E%3CfeBlend in2='BackgroundImageFix' result='effect1_dropShadow_1587_230429'/%3E%3CfeBlend in='SourceGraphic' in2='effect1_dropShadow_1587_230429' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E\") 32 32, pointer",
    },
  },
}))

const ProductZoomImagesSlider = styled(ProductImagesCarousel, {
  name: 'ProductZoom',
  slot: 'ImagesSlider',
})(() => ({
  padding: '0',
  maxHeight: 'inherit',

  // ProductImageZoomMagnifier
  '& + div': {
    visibility: 'hidden',
  },

  // ProductImageZoomMagnifier
  '&:hover + div': {
    visibility: 'visible',
  },
}))

const StyledShippingTimesContainer = styled('div', {
  name: 'ShippingTimesContainer',
  slot: 'ShippingTimesContainer',
})(({ theme }) => ({
  padding: theme.spacing(4),
  color: theme.palette.text.dark.primary,
  backgroundColor: theme.palette.background.light.secondary,
  fontSize: 12,
}))

const StyledSectionContainer = styled('div', {
  shouldForwardProp: prop => prop === 'visible' || prop === 'children',
})<{
  visible?: boolean
}>(({ visible }) => ({
  display: visible ? 'flex' : 'none',
}))

const StyledLoaderContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
}))

const StyledLoaderWrapper = styled('div')(() => ({
  display: 'flex',
  height: '100%',
}))

const StyledSuggestionContainer = styled('div')(({}) => ({
  gridColumn: '1 / -1',
}))

const StyledSelectLensesCTA = styled(Button)(({ theme }) => ({
  minWidth: 163,
  height: 40,
  flexGrow: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  whiteSpace: 'nowrap',
}))

const StyledRelatedProduct = styled(Link, {
  name: 'PdpDetails',
  slot: 'RelatedProduct',
})(({ theme }) => ({
  display: 'flex',
  fontSize: 12,
  textDecoration: 'none',
  color: theme.palette.text.dark.primary,
  fontWeight: 600,
  alignItems: 'center',
  cursor: 'pointer',

  span: {
    textDecoration: 'underline',
  },
}))

const StyledImageWrapper = styled('div', {
  name: 'PdpDetails',
  slot: 'ImageWrapper',
})(() => ({
  height: 35,
  width: 66,
}))

const StyledCLAccessoriesContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'CLAccessoriesContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  rowGap: theme.spacing(8),
}))

const StyledCLAccessoriesPrice = styled('div', {
  name: 'PdpDetails',
  slot: 'CLAccessoriesPrice',
})({
  display: 'flex',
  alignItems: 'flex-end',
})

const StyledCLAccessoriesLabel = styled('div', {
  name: 'PdpDetails',
  slot: 'CLAccessoriesLabel',
})({
  fontSize: 12,
  fontWeight: 'bold',
  display: 'flex',
  flexGrow: 2,
})

const StyledCLAccessoriesInitialLabel = styled('span', {
  name: 'PdpDetails',
  slot: 'CLAccessoriesInitialLabel',
})(({ theme }) => ({
  fontSize: 12,
  textDecoration: 'line-through',
  paddingRight: theme.spacing(1.5),
}))

const StyledCLAccessoriesSelect = styled('div', {
  name: 'PdpDetails',
  slot: 'CLAccessoriesSelect',
})(({ theme }) => ({
  fontSize: 12,
  display: 'flex',
  flexGrow: 1,
  '.MuiSelect-icon': {
    top: `${theme.spacing(1.8)} !important`,
  },
  '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.background.light.quaternary,
  },
  '.MuiSelect-iconOpen': {
    transform: 'rotate(0deg)',
  },
  '.is-select': {
    minHeight: '33px',
  },
}))

const StyledCLAccessoriesWrapper = styled('div', {
  name: 'PdpDetails',
  slot: 'CLAccessoriesWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: 14,
  gap: theme.spacing(4),
  color: theme.palette.text.dark.primary,
}))

export const ProductPriceWrapper = styled('div', {
  name: 'ProductPrice',
  slot: 'Wrapper',
  shouldForwardProp: prop => prop !== 'isLoading' && prop !== 'plpStyle',
})<{ isLoading?: boolean; plpStyle?: 'normal' | 'gve' }>(({ theme, isLoading, plpStyle }) => ({
  minHeight: 26,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  '& > div': {
    display: isLoading ? 'inherit' : 'flex',
    flexGrow: 0,
    alignItems: plpStyle === 'gve' ? 'center' : 'baseline',
    whiteSpace: 'wrap',
    flexDirection: plpStyle === 'gve' ? 'column' : 'row',
    gap: plpStyle === 'gve' ? '4px' : '4px',
  },
  '.initial-price': {
    flexDirection: plpStyle === 'gve' ? 'row-reverse' : 'inherit',
  },
  [theme.breakpoints.down(391)]: {
    flexWrap: 'wrap',
  },
}))
export const StyledProductPricePricePerBox = styled(Typography, {
  name: 'ProductPrice',
  slot: 'PricePerBox',
})(() => ({
  textAlign: 'left',
  width: 'inherit',
}))

const StyledProductBoxesQuantity = styled('div', {
  name: 'PdpDetails',
  slot: 'BoxesQuantity',
})(() => ({
  display: 'flex',
  justifyContent: 'start',
}))

export const StyledProductPricePricePerBoxPrices = styled('div', {
  name: 'ProductPrice',
  slot: 'PricePerBoxPrices',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 0,
  alignItems: 'baseline',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  padding: 0,
  minWidth: theme.spacing(40),
}))
export const StyledProductPricePricePerBoxCurrentPrice = styled(Typography, {
  name: 'ProductPrice',
  slot: 'PricePerBoxCurrentPrice',
})({
  flexGrow: 0,
  textAlign: 'right',
})
const FramesPdpRightColumnInfoContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'FrameInfoContainer',
})(({ theme }) => ({
  padding: `${theme.spacing(3)} 0`,
  fontSize: 12,
}))

const FramesPdpRightColumnInfo = styled('div', {
  name: 'PdpDetails',
  slot: 'FrameInfo',
})(({ theme }) => ({
  marginBottom: theme.spacing(2),
  'span:first-of-type': {
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(1),
  },
}))

const VirtualMirrorPill = styled(Pill, {
  name: 'VirtualMirror',
  slot: 'Pill',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  backgroundColor: '#f6f6f6',
  border: '1px solid #d8d8d8',
}))

const ProductDetailsListMobile = styled(GridItem, {
  name: 'ProductDetailsList',
  slot: 'Wrapper',
})(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))

const ProductDetailsListDesktop = styled('div', {
  name: 'ProductDetailsList',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}))

const StyledPDPSliderWrapper = styled('div', {
  name: 'PdpDetails',
  slot: 'SliderWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(4),
}))

const StyledPDPSliderThumbWrapper = styled('div', {
  name: 'PdpDetails',
  slot: 'SliderThumbWrapper',
})(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(16),
  width: '100%',
}))

const StyeldPDPMainSlide = styled(Skeleton, {
  name: 'PdpDetails',
  slot: 'MainSlide',
})(() => ({
  borderRadius: 0,
  width: '100%',
  height: '100%',
  aspectRatio: '1/1',
}))

const StyeldPDPMainSlideThumb = styled(Skeleton, {
  name: 'PdpDetails',
  slot: 'MainSlideThumb',
})(() => ({
  borderRadius: 0,
  width: '100%',
  height: '100%',
  aspectRatio: '1/1',
}))

export {
  FramesPdpRightColumnInfo,
  FramesPdpRightColumnInfoContainer,
  PdpContainer,
  PdpLeftColumn,
  PdpRightColumn,
  ProductDetailsListMobile,
  ProductDetailsListDesktop,
  ProductFullPageGalleryImagesSlider,
  ProductFullPageGalleryThumbsSlider,
  ProductImagesMainSlider,
  ProductZoomImagesSlider,
  SliderWrapper,
  SoldOutDescription,
  SoldOutImagePlaceholderContainer,
  SoldOutTitle,
  StyledButton,
  StyledCLAccessoriesContainer,
  StyledCLAccessoriesInitialLabel,
  StyledCLAccessoriesLabel,
  StyledCLAccessoriesPrice,
  StyledCLAccessoriesSelect,
  StyledCLAccessoriesWrapper,
  StyledImageWrapper,
  StyledLeftColumnContainer,
  StyledLoaderContainer,
  StyledLoaderWrapper,
  StyledProductBoxesQuantity,
  StyledProductName,
  StyledRelatedProduct,
  StyledSectionContainer,
  StyledSelectLensesCTA,
  StyledSeparator,
  StyledShippingTimesContainer,
  StyledSuggestionContainer,
  StyledTitleContent,
  VirtualMirrorPill,
  VirtualMirrorWrapper,
  StyledPdpSummary,
  StyledPDPSliderWrapper,
  StyledPDPSliderThumbWrapper,
  StyeldPDPMainSlide,
  StyeldPDPMainSlideThumb,
}
