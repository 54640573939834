import { ContactLensesSelectProps } from './ContactLensesSelect'
import { alpha, Typography, styled } from '@mui/material'
import SvgChevronDown from '@components/UI/Icons/VD/General/ChevronDown'

export const ContactLensesSelectWrapper = styled('div', {
  name: 'ContactLensesSelect',
  slot: 'Wrapper',
})(() => ({
  display: 'grid',
  gridTemplateColumns: '8fr 9fr 9fr',
}))

export const DropDownContainer = styled('div', {
  name: 'MultiFieldsDropDown',
  slot: 'Container',
  shouldForwardProp: props => props !== 'open' && props !== 'error' && props !== 'disabled' && props !== 'className',
})<{
  open: boolean
  error: boolean
  disabled: boolean
  className
}>(({ open, error, disabled, theme }) => ({
  backgroundColor: theme.palette.primary.tones[100],
  position: 'relative',
  ...theme.typography.body1,
  border: `${open || error ? '0' : '1px'} solid ${
    open ? theme.palette.primary.main : error ? theme.palette.error.main : theme.palette.neutral.tones[80]
  }`,
  borderRadius: 8,
  padding: theme.spacing(0),
  opacity: disabled ? '0.5' : '1',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  '&.contact-lens-quantity-select': {
    minWidth: 218,
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    backgroundColor: theme.palette.primary.tones[100],
    ...theme.typography.body1,
    border: !!error ? `1px solid ${theme.palette.error.main}` : `1px solid ${theme.palette.neutral.tones[80]}`,
    borderRadius: '8px',
    '&:hover': {
      borderColor: theme.palette.neutral.main,
    },
  },
}))

export const StyledContactLensesSelectValueField = styled('button', {
  name: 'ContactLensesSelect',
  slot: 'ValueField',
})(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.neutral.main,
  cursor: 'pointer',
  backgroundColor: 'transparent',
  border: 'none',
  height: '100%',
  width: '100%',
  textAlign: 'left',
  '&.contact-lens-quantity-select': {
    color: theme.palette.primary.tones[0],
    width: 'fit-content',
    borderRadius: '8px',
    padding: theme.spacing(0, 20, 0, 0),
  },
}))

export const DropDownPopper = styled('div', {
  name: 'MultiFieldsDropDown',
  slot: 'Popper',
  shouldForwardProp: props => props !== 'open' && props !== 'className',
})<{ open: ContactLensesSelectProps['open']; className: string | undefined }>(({ theme, open, className }) => ({
  zIndex: open ? '2' : '-1',
  lineHeight: theme.spacing(8.25),
  top: '100%',
  left: '0',
  position: 'absolute',
  width: '100%',
  opacity: open ? '1' : '0',
  display: 'block',
  transition: `opacity 0.2s ${open ? 'ease-in' : 'ease-out'}`,
  backgroundColor: theme.palette.common.white,
  borderRadius: '4px',
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
  border: `solid 2px ${theme.palette.primary.main}`,
  boxShadow: '5px 5px 10px 0 rgb(0 15 66 / 15%)',
  maxHeight: theme.spacing(126),
  overflowX: 'hidden',
  '&.contact-lens-quantity-select': {
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
}))

export const MultiDropDownContent = styled('ul', {
  name: 'MultiFieldsDropDown',
  slot: 'Content',
  shouldForwardProp: props => props !== 'multifield' && props !== 'className',
})<{ multifield: ContactLensesSelectProps['multifield'] }>(({ theme, multifield, className }) => ({
  display: multifield ? 'grid' : 'flex',
  gridTemplateColumns: multifield ? '1fr 1fr' : 'initial',
  gridGap: multifield ? '0' : 'initial',
  flexDirection: multifield ? 'initial' : 'column',
  position: 'relative',
  width: '100%',
  margin: '0',
  padding: '0',
  textAlign: 'center',
  maxHeight: '200px',
  height: '100%',
  scrollbarWidth: 'thin',
  scrollbarColor: `${theme.palette.background.light.quaternary} ${theme.palette.background.light.tertiary}`,
  '&::-webkit-scrollbar': {
    width: '4px',
    backgroundColor: theme.palette.background.light.tertiary,
  },
  '&::-webkit-scrollbar-thumb': {
    height: '50%',
    backgroundColor: theme.palette.background.light.quaternary,
    borderRadius: '50vmax',
  },
}))

export const DropDownIcon = styled(SvgChevronDown, {
  name: 'MultiFieldsDropDown',
  slot: 'Icon',
  shouldForwardProp: props => props !== 'className',
})<{ open?: boolean }>(({ open, theme }) => ({
  position: 'absolute',
  right: '10px',
  top: '8px',
  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: 'all 0.3s',
  width: '1.5rem',
  height: '1.5rem',
  '&.contact-lens-quantity-select': {
    top: '50%',
    transform: `translateY(-50%) rotate(${open ? '180deg' : '0deg'})`,
    color: theme.palette.neutral.tones[60],
  },
}))

export const DropDownListItem = styled('li', {
  name: 'MultiFieldsDropDown',
  slot: 'ListItem',
  shouldForwardProp: props =>
    props === 'onClick' || props === 'children' || props === 'className' || props === 'id' || props === 'role',
})<{
  multifield: ContactLensesSelectProps['multifield']
  onClick?: (e: React.MouseEvent<HTMLLIElement>, val: string | null) => void
  className
  id?: string
  role?: string
}>(({ theme, multifield }) => ({
  flex: multifield ? '1 0 50%' : '1 0 100%',
  width: '100%',
  listStyle: 'none',
  cursor: 'pointer',
  backgroundColor: theme.palette.primary.tones[100],
  '&:hover': {
    backgroundColor: theme.palette.neutral.tones[95],
  },
  '&.contact-lens-quantity-select': {
    ...theme.typography.body1,
    textAlign: 'left',
    padding: theme.spacing(0, 2),

    '&.selected': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.12),
      },
    },
  },
}))

export const StyledDropDownListItemButton = styled(Typography, {
  name: 'MultiFieldsDropDown',
  slot: 'ListItemButton',
})(({ theme }) => ({
  display: 'block',
  color: theme.palette.neutral.main,
  padding: theme.spacing(2),

  '&.contact-lens-quantity-select': {
    color: theme.palette.primary.tones[0],
  },
}))

export const DropDownContainerInner = styled('div', {
  name: 'MultiFieldsDropDown',
  slot: 'ContainerInner',
  shouldForwardProp: props => props !== 'open' && props !== 'error' && props !== 'disabled',
})<{
  open: boolean
  error: boolean
  disabled: boolean
}>(({ open, error, disabled, theme }) => ({
  border: `2px solid ${open ? theme.palette.primary.main : error ? theme.palette.error.main : 'transparent'}`,
  borderRadius: 8,
  padding: theme.spacing(2.5, 4),
  opacity: disabled ? '0.5' : '1',
  display: 'flex',
  alignItems: 'center',
  flex: 1,

  '> button': {
    padding: `${open ? theme.spacing(0, '1px') : 0}`,
  },
}))
