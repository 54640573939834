import { BundleOfferProps } from './types/BundleOffer.types'
import { Typography } from '@mui/material'
import { CmsMedia } from '@components/common/components/CmsMedia'
import useProductBundleData from './hooks/useProductBundleData'
import useBundlerOfferPrices from './hooks/useBundlerOfferPrices'
import ProductTilePrice from '@pages_views/ProductDetails/components/ProductTilePrice'
import useBundlePlacements from './hooks/useBundlePlacements'
import { bundleOfferImageCrops } from './constants/BundleOfferImageCrops'
import { StyledBundleBox, StyledImageWrapper, StyledOfferPrice, StyledOriginalPrice } from './BundleOffers.style'
import { multiplyBundleAmount } from './utils/BundleOfferPrice.utils'
import { StyledPrimaryButton } from '@pages_views/ProductDetails/components/cta/CtaPdp.style'
import { useSelector } from 'react-redux'
import { isAddingItemSelector } from '@features/order/selector'
import { useAddOrderItem } from '@pages_views/ProductDetails/hooks/useAddOrderItem'
import { StyledLoader } from '@components/UI/Loader'

const BundleOffers = (props: BundleOfferProps) => {
  const { product, placements = [] } = props
  const { partNumber, currency } = useProductBundleData(product)
  const { originalPrice, offerPrice } = useBundlerOfferPrices(product)
  const { hasBundleOffer, productBundleImages, bundleQuantity, bundleTitle, bundleAddToCartText } =
    useBundlePlacements(placements)
  const isAddingItem = useSelector(isAddingItemSelector)
  const { addToCart } = useAddOrderItem(undefined, product, partNumber, [`${bundleQuantity}`])

  if (!hasBundleOffer || !partNumber) {
    return null
  }

  return (
    <StyledBundleBox withBorder>
      {bundleTitle ? <Typography variant="subtitle1">{bundleTitle}</Typography> : null}
      {productBundleImages && productBundleImages.length > 0 ? (
        <StyledImageWrapper>
          <CmsMedia media={productBundleImages} imageCrops={bundleOfferImageCrops} alt={bundleTitle} />
        </StyledImageWrapper>
      ) : null}

      <ProductTilePrice
        variant="body2"
        offerPrice={offerPrice ? <StyledOriginalPrice price={offerPrice} currency={currency} /> : <></>}
        rrp={<StyledOfferPrice price={multiplyBundleAmount(originalPrice, bundleQuantity)} currency={currency} />}
      />

      {bundleQuantity && (
        <StyledPrimaryButton
          variant="secondary"
          fillType={'fill'}
          data-element-id={'upsell-offer-add-to-cart'}
          data-description={`${partNumber}`}
          disabled={isAddingItem}
          loading={isAddingItem}
          isAddingItem={isAddingItem}
          onClick={() => addToCart()}
        >
          <StyledLoader size={20} loading={isAddingItem} />
          {bundleAddToCartText}
        </StyledPrimaryButton>
      )}
    </StyledBundleBox>
  )
}
export default BundleOffers
