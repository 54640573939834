import useTheme from '@mui/material/styles/useTheme'
import React from 'react'
import { useTranslation } from 'next-i18next'
import { StyledTypography } from '@components/UI/Typography'

export const BridgeGuide = React.forwardRef<HTMLDivElement>(function BridgeGuide(_, ref) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <div ref={ref as unknown as React.LegacyRef<HTMLDivElement>}>
      <StyledTypography
        isUppercased
        marginBottom={4}
        fontSize={20}
        fontWeight={'600'}
        color={theme.palette.primary.dark}
      >
        {t('SizeFitGuide.bridge.title')}
      </StyledTypography>
      <StyledTypography
        marginBottom={4}
        color={theme.palette.primary.dark}
        dangerouslySetInnerHTML={{
          __html: t('SizeFitGuide.bridge.description1') || '',
        }}
      />
    </div>
  )
})
